<template>
  <div class="vip-page font-regular" v-loading="loading">
    <div class="banner-section">
      <div class="container container-1240">
        <div class="banner-img">
          <img src="@/assets/images/vip/banner-bg.png" />
        </div>
        <div class="banner-title font-semibold">价值够大，价格够低</div>
        <div class="banner-sub-title font-semibold">
          全新的学习系统，将尖端技术和系统性训练体系融为一体。
        </div>
        <div class="banner-desc">
          和500万开发者一起持续提升，更早实现更大价值。成为VIP，享受所有会员权限，更多内容、更多收益，做极客，做更优质开发者。
        </div>
        <div class="feature-list font-semibold">
          <div class="feature-item">
            <div class="item-num">500+</div>
            <div class="item-desc">图文教程</div>
          </div>
          <div class="feature-item">
            <div class="item-num">3000+</div>
            <div class="item-desc">视频课程</div>
          </div>
          <div class="feature-item">
            <div class="item-num">8个</div>
            <div class="item-desc">职业方向</div>
          </div>
          <div class="feature-item">
            <div class="item-num">310万</div>
            <div class="item-desc">认证学员</div>
          </div>

          <div class="feature-item">
            <div class="item-num">2000+</div>
            <div class="item-desc">导师教练</div>
          </div>
          <div class="feature-item">
            <div class="item-num">500+</div>
            <div class="item-desc">实战项目</div>
          </div>
        </div>
      </div>
    </div>
    <div class="vip-section">
      <div class="container container-1240">
        <div class="vip-list">
          <div class="vip-item">
            <div class="item-title font-semibold">VIP会员</div>
            <div class="item-price">
              ¥<span class="price-highlight font-semibold">{{
                (priceList[0].prices[0] || {}).price
              }}</span
              >元<span class="price-payment">/月/人</span>
            </div>
            <div class="item-desc">初级学习计划 <br />会员初级体验</div>
            <div class="item-btn-container">
              <span
                class="item-btn"
                @click="handlePayVip(priceList[0].prices[0].id)"
                >立即加入</span
              >
              <i class="btn-icon" />
            </div>
          </div>
          <div class="vip-item">
            <div class="item-title font-semibold">VIP会员 - PRO</div>
            <div class="item-price">
              ¥<span class="price-highlight font-semibold">{{
                (priceList[1].prices[0] || {}).price
              }}</span
              >元<span class="price-payment">/月/人</span>
            </div>
            <div class="item-desc">中级持续进阶<br />实现开发水平显著提高</div>
            <div class="item-btn-container">
              <span
                class="item-btn"
                @click="handlePayVip(priceList[1].prices[0].id)"
                >立即加入</span
              >
              <i class="btn-icon" />
            </div>
          </div>
          <div class="vip-item">
            <div class="item-title font-semibold">VIP会员 - PLUS</div>
            <div class="item-price">
              ¥<span class="price-highlight font-semibold">{{
                (priceList[2].prices[0] || {}).price
              }}</span
              >元<span class="price-payment">/月/人</span>
            </div>
            <div class="item-desc">高级进阶<br />让学习成就自己成为极客</div>
            <div class="item-btn-container">
              <span
                class="item-btn"
                @click="handlePayVip(priceList[2].prices[0].id)"
                >立即加入</span
              >
              <i class="btn-icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="qa-section">
      <div class="container container-1240">
        <div class="section-title font-semibold">常见问题</div>
        <div class="qa-list">
          <div class="qa-item">
            <div class="q-container">
              <i class="q-icon" />
              <div class="q-content font-semibold">
                你们和动辄上万块的卖课机构逻辑不太一样，有什么故事么？
              </div>
            </div>
            <div class="a-container">
              <i class="a-icon" />
              <div class="a-content">
                极客学院本着“让学习更有效“的使命，把“为更多人提供高质量的职业教育”做为奋斗的目标，一直在探索如何提供更高性价比的内容和服务
              </div>
              <div class="a-content">
                我们认为学习是”综合的服务“而不是”单纯的商品“，推崇”时间无价“，提倡按”时间订阅“的学习方式，为了让你觉得极客学院是良心的、超值的、有温度的而不断努力。
              </div>
            </div>
          </div>
          <div class="qa-item">
            <div class="q-container">
              <i class="q-icon" />
              <div class="q-content font-semibold">
                我真想学习，但是现在比较拮据，也比较迷茫，有什么建议么？
              </div>
            </div>
            <div class="a-container">
              <i class="a-icon" />
              <div class="a-content">我们的建议是：学习可改变命运！</div>
              <div class="a-content">
                极客学院有很多”心存梦想，不甘现状“的同学，他们也曾很迷茫，但是通过自己的努力，正让自己变得越来越好。
              </div>
              <div class="a-content">
                为了让每个想学习的人能机会学习，极客学院会不断推出各种措施，比如限时优惠，学生特惠等，如果还有困难，你也可以在右侧联系我们寻求帮助。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import payService from "@/global/service/pay";
export default {
  data() {
    return {
      priceList: [],
      loading: false,
    };
  },
  computed: {
    // ...mapState(["userInfo"]),
  },
  created() {
    this.getPrice();
  },
  methods: {
    getPrice() {
      this.loading = true;
      payService
        .vipPrices()
        .then((res) => {
          console.log(res);
          this.priceList = res;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handlePayVip(id) {
      console.log(id);
      const url = this.$router.resolve({
        name: "JkxyPay",
        params: {
          id,
        },
      });
      window.open(url.href);
    },
  },
};
</script>

<style type="text/css" lang="less" scoped></style>
<style type="text/css" lang="less" scoped>
.vip-page {
  position: relative;
  width: 100%;
}
.banner-section {
  padding: 115px 0 335px;
  background-color: #ffffff;
  .container {
    position: relative;
    .banner-img {
      position: absolute;
      top: -82px;
      left: 50%;
      margin-left: 70px;
      width: 628px;
      height: 450px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .banner-title {
      height: 84px;
      margin-bottom: 16px;
      font-size: 60px;
      line-height: 84px;
      color: #333333;
    }
    .banner-sub-title {
      height: 25px;
      margin-bottom: 31px;
      font-size: 18px;
      color: #333333;
      line-height: 25px;
    }
    .banner-desc {
      width: 557px;
      margin-bottom: 31px;
      font-size: 16px;
      color: #333333;
      line-height: 30px;
    }
    .feature-list {
      position: absolute;
      top: 277px;
      left: 0;
      display: flex;
      align-items: center;
      .feature-item {
        margin-right: 50px;
        .item-num {
          height: 45px;
          font-size: 32px;
          color: #333333;
          line-height: 45px;
        }
        .item-desc {
          height: 22px;
          font-size: 16px;
          color: #333333;
          line-height: 22px;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
.vip-section {
  position: absolute;
  top: 542px;
  left: 50%;
  transform: translateX(-50%);
  .container {
    .vip-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .vip-item {
        width: 327px;
        padding: 54px 30px 43px;
        background-color: #ffffff;
        border: 1px solid rgba(232, 232, 232, 1);
        .item-title {
          height: 45px;
          margin-bottom: 15px;
          text-align: center;
          font-size: 32px;
          color: #333333;
          line-height: 45px;
          &.font-semibold {
            font-weight: 600;
          }
        }
        .item-price {
          height: 33px;
          margin-bottom: 25px;
          line-height: 33px;
          text-align: center;
          vertical-align: bottom;
          font-size: 24px;
          letter-spacing: 1px;
          color: #333333;
          .price-highlight {
            color: #0fc700;
            font-weight: 600;
          }
          .price-payment {
            font-size: 16px;
            font-weight: 400;
          }
        }
        .item-desc {
          height: 60px;
          margin-bottom: 68px;
          text-align: center;
          font-size: 16px;
          color: #333333;
          line-height: 30px;
        }
        .item-btn-container {
          position: relative;
          width: 160px;
          height: 42px;
          margin: 0 auto;
          background-color: #333333;
          cursor: pointer;
          .item-btn {
            display: inline-block;
            width: 100%;
            height: 100%;
            color: #ffffff;
            line-height: 42px;
            font-size: 16px;
            padding-left: 34px;
            text-align: left;
          }
          .btn-icon {
            position: absolute;
            top: 14px;
            right: 40px;
            display: inline-block;
            width: 14px;
            height: 14px;
            background: url("~@/assets/images/vip/icon-arrow.svg") 0 0 no-repeat;
          }
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
}
.qa-section {
  padding-top: 270px;
  padding-bottom: 125px;
  background-color: #f5f5f5;
  .section-title {
    height: 37px;
    margin-bottom: 32px;
    font-size: 26px;
    color: #333333;
    line-height: 37px;
  }
  .qa-list {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .qa-item {
      width: 506px;
      .q-container {
        margin-bottom: 9px;
        .q-icon {
          display: inline-block;
          width: 22px;
          height: 26px;
          margin-bottom: 8px;
          background: url("~@/assets/images/vip/icon-q.svg") 0 0 no-repeat;
        }
        .q-content {
          height: 49px;
          font-size: 18px;
          color: rgba(51, 51, 51, 1);
          line-height: 25px;
        }
      }
      .a-container {
        .a-icon {
          display: inline-block;
          width: 22px;
          height: 26px;
          background: url("~@/assets/images/vip/icon-a.svg") 0 0 no-repeat;
        }
        .a-content {
          font-size: 14px;
          color: #333333;
          line-height: 30px;
          margin-bottom: 30px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
</style>
